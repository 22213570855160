<template>
  <DefaultLayout>
    <template v-slot:toolbar>
      <v-btn icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title>
        {{ exam.subjects_name }} - {{ exam.exam_type_label }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="showRightBar">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </template>

    <v-container class="px-0">
      <h1 class="mx-2">{{ exam.exam_type_label }}</h1>
      <small class="mx-2">{{ exam.subjects_name }}</small>
      <v-divider></v-divider>
      <v-card elevation="0">
        <v-card-text class="px-0 mx-0">
          <v-row>
            <v-col cols="6" class="py-0">
              <v-list>
                <v-list-item two-line dense class="mx-0 px-0 my-0 py-0">
                  <v-list-item-content class="px-2 py-0 my-0">
                    <v-list-item-title>Mata Pelajaran :</v-list-item-title>
                    <v-list-item-subtitle>{{
                      exam.subjects_name
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line dense class="mx-0 px-0 my-0 py-0">
                  <v-list-item-content class="px-2 py-0 my-0">
                    <v-list-item-title>Guru :</v-list-item-title>
                    <v-list-item-subtitle
                      >{{ exam.teacher_firstname }}
                      {{ exam.teacher_lastname }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line dense class="mx-0 px-0 my-0 py-0">
                  <v-list-item-content class="px-2 py-0 my-0">
                    <v-list-item-title>Kategori :</v-list-item-title>
                    <v-list-item-subtitle
                      >{{ exam.exam_category }} ({{
                        exam.exam_category_label
                      }})</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line dense class="mx-0 px-0 my-0 py-0">
                  <v-list-item-content class="px-2 py-0 my-0">
                    <v-list-item-title>Tipe :</v-list-item-title>
                    <v-list-item-subtitle
                      >{{ exam.exam_type }} ({{
                        exam.exam_type_label
                      }})</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-list>
                <v-list-item two-line dense class="mx-0 px-0 my-0 py-0">
                  <v-list-item-content class="px-2 py-0 my-0">
                    <v-list-item-title>Mulai :</v-list-item-title>
                    <v-list-item-subtitle>{{
                      exam.exam_start_time
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line dense class="mx-0 px-0 my-0 py-0">
                  <v-list-item-content class="px-2 py-0 my-0">
                    <v-list-item-title>Selesai :</v-list-item-title>
                    <v-list-item-subtitle>{{
                      exam.exam_end_time
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line dense class="mx-0 px-0 my-0 py-0">
                  <v-list-item-content class="px-2 py-0 my-0">
                    <v-list-item-title>Status :</v-list-item-title>
                    <v-list-item-subtitle>{{
                      exam.exam_stat_label
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line dense class="mx-0 px-0 my-0 py-0">
                  <v-list-item-content class="px-2 py-0 my-0">
                    <v-list-item-title>Sisa Waktu :</v-list-item-title>
                    <v-list-item-subtitle>{{ examTimer }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-divider></v-divider>
      <h4 class="mx-2 mt-6" v-if="exam.exam_stat == 1">
        Silahkan jawab soal di bawah ini dengan benar:
      </h4>
      <ExamSheetPG
        ref="refExamSheetPG"
        v-if="$route.query.exam_category == 'PG' && exam.exam_stat == 1"
        :code="$route.query.code"
      ></ExamSheetPG>
      <ExamSheetEssay
        ref="refExamSheetEssay"
        v-if="$route.query.exam_category == 'ESSAY' && exam.exam_stat == 1"
        :code="$route.query.code"
      ></ExamSheetEssay>
      <ExamSheetPGEssay
        ref="refExamSheetPGEssay"
        v-if="$route.query.exam_category == 'PGESSAY' && exam.exam_stat == 1"
        :code="$route.query.code"
      ></ExamSheetPGEssay>
    </v-container>
    <button-go-top></button-go-top>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import ExamSheetPG from "@/components/ExamSheetPG.vue";
import ExamSheetEssay from "@/components/ExamSheetEssay.vue";
import ExamSheetPGEssay from "@/components/ExamSheetPGEssay.vue";
import ButtonGoTop from "@/components/ButtonGoTop.vue";
import moment from "moment";

export default {
  name: "ExamStart",
  components: {
    DefaultLayout,
    ExamSheetPG,
    ButtonGoTop,
    ExamSheetPGEssay,
    ExamSheetEssay,
  },
  data: () => ({
    exam: {},
    loading: null,
    isPG: false,
    isPGESSAY: false,
    isESSAY: false,
    isPRAKTIKUM: false,
  }),

  computed: {
    currentTime() {
      return moment();
    },

    examTimer() {
      // var a = this.currentTime;
      var b = moment(this.exam.exam_end_time);
      return moment(b).fromNow(); // 10 years ago
    },
  },
  methods: {
    async getExamDetail() {
      (this.isPGESSAY = false),
        (this.isESSAY = false),
        (this.isPRAKTIKUM = false),
        (this.exam = {});
      var data = {
        params: {
          code: this.$route.query.code,
        },
      };
      await this.$axios.get("exam/detail", data).then((res) => {
        this.$store.state.showLoadingDialog = false;
        if (res.data.status == "success") {
          this.exam = res.data.data;
          if (this.exam.exam_category == "PG") {
            this.isPG = true;
            if (typeof this.$refs.refExamSheetPG !== "undefined")
              this.$refs.refExamSheetPG.getQuestion(this.exam.code);
          }
        } else {
          this.exam = {};
        }
      });
    },

    showRightBar() {
      if (this.$route.query.exam_category == "PG" && this.exam.exam_stat == 1) {
        this.$refs.refExamSheetPG.showRightBar();
      }
      if (
        this.$route.query.exam_category == "ESSAY" &&
        this.exam.exam_stat == 1
      ) {
        this.$refs.refExamSheetEssay.showRightBar();
      }
      if (
        this.$route.query.exam_category == "PGESSAY" &&
        this.exam.exam_stat == 1
      ) {
        this.$refs.refExamSheetPGEssay.showRightBar();
      }
    },
  },
  beforeMount() {
    if (this.$store.state.isLoggedIn == false) {
      this.$router.push("/login");
    }
  },
  mounted() {
    this.getExamDetail();
    this.setPageTitle("Ujian", "mdi-pen");
  },
};
</script>
