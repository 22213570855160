<template>
  <DefaultLayout>
    <template slot="toolbar">
      <slot name="appDrawer"></slot>
      <v-toolbar-title class="pl-1">
        <v-icon v-if="$store.state.pageIcon" class="pb-1 pr-2">{{
          $store.state.pageIcon
        }}</v-icon>
        <span>{{ $store.state.pageTitle }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="getCategory">
        <v-icon>mdi-sync</v-icon>
      </v-btn>
    </template>

    <v-container>
        <div class="text-h6 ml-4 mb-2">
          <v-icon color="blue">{{ $store.state.pageIcon }}</v-icon>
          {{ $store.state.pageTitle }}
        </div>
        <!-- <v-divider class="mb-4"></v-divider> -->
      <v-sheet elevation="0">
        <v-tabs
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
          v-model="tab"
          background-color="transparent"
        >
          <v-tab
            v-for="(item, i) in examType"
            :key="i"
            @click="getList(item)"
            >{{ item.label }}</v-tab
          >
        </v-tabs>
      </v-sheet>

      <v-divider></v-divider>

      <v-skeleton-loader
        v-if="loading"
        max-width="300"
        type="list-item-two-line, list-item-two-line, list-item-two-line"
      ></v-skeleton-loader>

      <p v-if="examList.length == 0" class="text-center py-6">
        Belum ada data.
      </p>

      <v-list v-if="examList.length >= 0">
        <v-list-item
          three-line
          v-for="(exam, i) in examList"
          :key="i"
          @click="detail(exam)"
        >
          <v-badge
            bordered
            color="green accent-4"
            dot
            offset-x="-5"
            offset-y="25"
            :value="exam.exam_stat"
            left
          >
            <v-list-item-content>
              <v-list-item-title :style="(exam.exam_stat == 1) ? 'color:green':''">
                {{ exam.subjects_name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <span>{{ exam.exam_category }}</span> |
                <span
                  >{{ exam.teacher_firstname }}
                  {{ exam.teacher_lastname }}</span
                >
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <span
                  >{{ exam.exam_start_time }} - {{ exam.exam_end_time }}</span
                ><br />
                <span>{{ exam.exam_stat_label }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-badge>
        </v-list-item>
      </v-list>
    </v-container>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Masuk Ujian/Test
        </v-card-title>

        <v-list>
          <v-list-item two-line>
            <v-list-item-content class="px-2">
              <v-list-item-title>Mata Pelajaran :</v-list-item-title>
              <v-list-item-subtitle>{{
                selectedExam.subjects_name
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content class="px-2">
              <v-list-item-title>Guru :</v-list-item-title>
              <v-list-item-subtitle
                >{{ selectedExam.teacher_firstname }}
                {{ selectedExam.teacher_lastname }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content class="px-2">
              <v-list-item-title>Kategori :</v-list-item-title>
              <v-list-item-subtitle
                >{{ selectedExam.exam_category }} ({{
                  selectedExam.exam_category_label
                }})</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content class="px-2">
              <v-list-item-title>Tipe :</v-list-item-title>
              <v-list-item-subtitle
                >{{ selectedExam.exam_type }} ({{
                  selectedExam.exam_type_label
                }})</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content class="px-2">
              <v-list-item-title>Mulai :</v-list-item-title>
              <v-list-item-subtitle>{{
                selectedExam.exam_start_time
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content class="px-2">
              <v-list-item-title>Selesai :</v-list-item-title>
              <v-list-item-subtitle>{{
                selectedExam.exam_end_time
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content class="px-2">
              <v-list-item-title>Status :</v-list-item-title>
              <v-list-item-subtitle>{{
                selectedExam.exam_stat_label
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false"> Tutup </v-btn>
          <v-btn
            color="primary"
            v-if="selectedExam.exam_stat == 1"
            link
            @click="checkIn(selectedExam)"
          >
            Mulai
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";

export default {
  name: "Exam",
  components: {
    DefaultLayout,
  },
  data: () => ({
    dialog: false,
    examType: [],
    examList: [],
    selectedExam: {},
    tab: {},
    loading: null,
  }),
  methods: {
    getCategory() {
      this.$axios.get("exam/exam-type").then((res) => {
        this.$store.state.showLoadingDialog = false;
        if (res.data.status == "success") {
          this.examType = res.data.data;

          this.getList();
        } else {
          this.examType = [];
        }
      });
    },

    async getList(item = null) {
      try {
        if (item == null) {
          item = this.examType[0];
        }

        this.loading = true;
        var data = {
          params: {
            exam_type: item.value,
          },
        };
        await this.$axios.get("exam/list", data).then((res) => {
          this.$store.state.showLoadingDialog = false;
          if (res.data.status == "success") {
            this.examList = res.data.data;
          } else {
            this.showAlert(res.data.status, res.data.message);
            this.examList = [];
          }
          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        this.showAlert("error", error);
      }
    },

    detail(exam = {}) {
      this.selectedExam = exam;

      this.dialog = true;
    },

    checkIn(exam = {}) {
      this.dialog = false;

      this.$router.push({
        name: "ExamStart",
        query: {
          code: exam.code,
          exam_type: exam.exam_type,
          exam_category: exam.exam_category,
        },
      });
    },
  },
  beforeMount() {
    if (this.$store.state.isLoggedIn == false) {
      this.$router.push("/login");
    }
  },
  mounted() {
    this.getCategory();
    this.setPageTitle("Ujian", "mdi-pen");
  },
};
</script>
