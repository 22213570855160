<template>
  <div>
    <v-card :loading="loading" class="mx-2" elevation="0">
      <v-card-text>Jumlah Soal: {{ questionDetail.length }}</v-card-text>
      <v-skeleton-loader
        v-if="loading == true"
        class="mx-auto"
        type="card, list-item-two-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-if="loading == true"
        class="mx-auto"
        type="card, list-item-two-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-if="loading == true"
        class="mx-auto"
        type="card, list-item-two-line"
      ></v-skeleton-loader>

      <div v-for="(q, i) in questionDetail" :key="q.id" class="my-1">
        <div :id="'sc' + i">
          <PilihanGanda :data="q" :sortNum="i + 1" :code="code"></PilihanGanda>
        </div>
      </div>
    </v-card>

    <RightBar title="Lompat Soal" ref="refRightBar">
      <v-list>
        <v-list-item
          v-for="(q, i) in questionDetail"
          :key="'goto' + i"
          @click="godoID('#sc' + i)"
        >
          <v-list-item-content>
            <v-list-item-title
              ><v-icon v-if="answered(q)">mdi-check</v-icon> #{{
                i + 1
              }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </RightBar>
  </div>
</template>

<script>
import PilihanGanda from "@/components/PilihanGanda.vue";
import RightBar from "../layouts/RightBar.vue";

export default {
  name: "ExamSheetPG",
  components: {
    PilihanGanda,
    RightBar,
  },
  props: {
    code: null,
  },
  data: () => ({
    exam: {},
    questionHeader: {},
    questionDetail: [],
    loading: null,
  }),

  methods: {
    answered(data) {
      if (data.opt_value1 == true) return true;
      if (data.opt_value2 == true) return true;
      if (data.opt_value3 == true) return true;
      if (data.opt_value4 == true) return true;
      if (data.opt_value5 == true) return true;
      if (data.opt_value6 == true) return true;
      return false;
    },

    async getQuestion() {
      this.loading = true;
      try {
        this.questionHeader = {};
        this.questionDetail = [];
        this.show = false;
        var data = {
          params: {
            code: this.code,
          },
        };
        await this.$axios.get("exam/question-sheet", data).then((res) => {
          this.loading = false;
          if (res.data.status == "success") {
            this.questionHeader = res.data.data.h;
            this.questionDetail = res.data.data.d;
          } else {
            this.questionHeader = {};
            this.questionDetail = [];
            this.showAlert("warning", "Not found!!!");
          }
        });
      } catch (error) {
        this.loading = false;
        this.showAlert("error", error);
      }
    },
    showRightBar() {
      this.$refs.refRightBar.show();
    },
    godoID(id) {
      this.$refs.refRightBar.hide();
      this.$nextTick(() => {
        this.$vuetify.goTo(id, {
          duration: 300,
          offset: 25,
          easing: "easeInOutCubic",
        });
      });
    },
  },
  mounted() {
    this.getQuestion();
  },
};
</script>
