<template>
  <v-card
    :loading="loading"
    outlined
    elevation="1"
    :style="answered ? 'border: 1px solid ' + cc : ''"
  >
    <v-card-title class="py-0">#{{ sortNum }}</v-card-title>
    <v-card-text v-html="q.question_text"></v-card-text>

    <v-card-text>
      <v-checkbox
        class="my-0 py-0"
        @click="setAnsw(q.id, data, !q.opt_value1)"
        dense
        v-model="q.opt_value1"
        v-if="q.opt1"
        :label="q.opt1"
        value="1"
      ></v-checkbox>
      <v-checkbox
        class="my-0 py-0"
        @click="setAnsw(q.id, data, !q.opt_value2)"
        dense
        v-model="q.opt_value2"
        v-if="q.opt2"
        :label="q.opt2"
        value="1"
      ></v-checkbox>
      <v-checkbox
        class="my-0 py-0"
        @click="setAnsw(q.id, data, !q.opt_value3)"
        dense
        v-model="q.opt_value3"
        v-if="q.opt3"
        :label="q.opt3"
        value="1"
      ></v-checkbox>
      <v-checkbox
        class="my-0 py-0"
        @click="setAnsw(q.id, data, !q.opt_value4)"
        dense
        v-model="q.opt_value4"
        v-if="q.opt4"
        :label="q.opt4"
        value="1"
      ></v-checkbox>
      <v-checkbox
        class="my-0 py-0"
        @click="setAnsw(q.id, data, !q.opt_value5)"
        dense
        v-model="q.opt_value5"
        v-if="q.opt5"
        :label="q.opt5"
        value="1"
      ></v-checkbox>
      <v-checkbox
        class="my-0 py-0"
        @click="setAnsw(q.id, data, !q.opt_value6)"
        dense
        v-model="q.opt_value6"
        v-if="q.opt6"
        :label="q.opt6"
        value="1"
      ></v-checkbox>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    code: String,
    data: Object,
    sortNum: Number,
  },
  data: () => ({
    loading: null,
    q: {},
  }),
  computed: {
    answered() {
      if (this.data.opt_value1 == true) return true;
      if (this.data.opt_value2 == true) return true;
      if (this.data.opt_value3 == true) return true;
      if (this.data.opt_value4 == true) return true;
      if (this.data.opt_value5 == true) return true;
      if (this.data.opt_value6 == true) return true;
      return false;
    },
    cc() {
      if (this.answered) return "green";
      return "";
    },
  },
  methods: {
    setAnsw(id, data) {
      try {
        this.loading = true;
        var fmData = new FormData();
        fmData.append("id", id);
        fmData.append("code", this.code);
        fmData.append("opt_value1", data.opt_value1);
        fmData.append("opt_value2", data.opt_value2);
        fmData.append("opt_value3", data.opt_value3);
        fmData.append("opt_value4", data.opt_value4);
        fmData.append("opt_value5", data.opt_value5);
        fmData.append("opt_value6", data.opt_value6);
        this.$axios.post("exam/set-answ/pg", fmData).then((res) => {
          this.loading = false;
          if (res.data.status !== "success") {
            var _data = res.data.data;
            this.q = _data;
          }
          if (res.data.status != "success") {
            this.showAlert(res.data.status, res.data.message);
          }
        });
      } catch (error) {
        this.loading = false;
        this.showAlert("error", error);
      }
    },
  },
  mounted() {
    this.q = this.data;
  },
};
</script>