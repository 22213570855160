<template>
  <v-card
    :loading="loading"
    outlined
    elevation="1"
    :style="answered ? 'border: 1px solid ' + cc : ''"
  >
    <v-card-title class="py-0">#{{ sortNum }}</v-card-title>
    <v-card-text v-html="q.question_text"></v-card-text>

    <div>
      <p class="mx-2">Jawab :</p>
      <tiptap-vuetify v-model="q.value_essay" :extensions="extensions" placeholder="Tulis jawaban disini ..." />
    </div>
    <v-card-actions>
      <v-btn @click="setAnsw(q.id, q)" small color="primary">Simpan</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  Image,
} from "tiptap-vuetify";

export default {
  components: { TiptapVuetify },

  props: {
    code: String,
    data: Object,
    sortNum: Number,
  },
  data: () => ({
    loading: null,
    q: {},
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
      Image,
    ],
  }),
  computed: {
    answered() {
      if (this.data.value_essay != "") return true;
      return false;
    },
    cc() {
      if (this.answered) return "green";
      return "";
    },
  },
  methods: {
    setAnsw(id, data) {
      try {
        this.loading = true;
        var fmData = new FormData();
        fmData.append("id", id);
        fmData.append("code", this.code);
        fmData.append("value_essay", data.value_essay);
        this.$axios.post("exam/set-answ/essay", fmData).then((res) => {
          this.loading = false;
          if (res.data.status !== "success") {
            var _data = res.data.data;
            this.q = _data;
          }
          if (res.data.status != "success") {
            this.showAlert(res.data.status, res.data.message);
          }
        });
      } catch (error) {
        this.loading = false;
        this.showAlert("error", error);
      }
    },
  },
  mounted() {
    this.q = this.data;
  },
};
</script>